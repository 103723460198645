import React, { useRef } from "react";
import Carousel from "react-elastic-carousel";
import "./bannerCarousel.css";
import { Capacitor } from "@capacitor/core";
interface Items {
  image: string;
}

interface IProps {
  items: Items[];
}
const BannerCarousel: React.FC<IProps> = ({ items }) => {
  const carousel = useRef<any>();

  return (
    <div className="carouselContainer m-0">
      <Carousel
        ref={carousel}
        showArrows={false}
        isRTL={false}
        enableAutoPlay
        autoPlaySpeed={3000}
        pagination={Capacitor.getPlatform() === "ios" ? true : false}
      >
        {items.map((item, index) => (
          <div className="w-screen" key={index}>
            <img src={item.image} alt={"image" + index} className="w-screen" />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default BannerCarousel;
