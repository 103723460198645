import React from "react";

interface Props {
  count: number;
  setCount: (count: number) => void;
  isPortrait?: boolean;
}

export const CartItemCount = ({ count, setCount, isPortrait }: Props) => {
  return (
    <div
      className={`flex mr-4 px-1 items-center border-2 rounded-full border-gray-400 ${
        isPortrait ? "text-3xl" : ""
      }`}
    >
      <input
        type="number"
        className="rounded-full text-center p-2 w-12"
        min="1"
        max="9999"
        value={count}
        onChange={(e) => setCount(parseInt(e.target.value))}
      />
      <div>
        <img
          src="/assets/icons/down-arrow.svg"
          className="transform rotate-180 mb-1 w-3 "
          alt=""
          onClick={() => setCount(count + 1)}
        />
        <img
          src="/assets/icons/down-arrow.svg"
          className="transform w-3 "
          alt=""
          onClick={() => setCount(count - 1)}
        />
      </div>
    </div>
  );
};
