import React, { useEffect, useMemo, useReducer, useState } from "react";
import * as yup from "yup";
import toast from "react-hot-toast";
import { Modal } from "../../Components/Modal";
import { Order } from "../../types";
import { useAppSelector } from "../../hooks/redux";
import { createOrder, patchOrder } from "../../api/ordersApi";
import { useMutation } from "react-query";
import { useNavigate } from "react-router";

let schema = yup.object().shape({
  customerName: yup.string().required(),
  customerNumber: yup
    .string()
    .matches(/^[0-9]{10}$/, "Phone number is not valid")
    .required(),
});

export default function OrderForm({
  show,
  closeNewOrderForm,
  currentOrder,
  clearCart,
}: {
  show: boolean;
  closeNewOrderForm: () => void;
  currentOrder: Order | null;
  clearCart: () => void;
}) {
  const [formErrors, setFormErrors] = useState<any>();
  const navigate = useNavigate();
  const { mutate } = useMutation(
    (order: Order) => (order._id ? patchOrder(order) : createOrder(order)),
    {
      onSuccess: () => {
        toast.success("Order saved successfully");
        clearCart();
        closeNewOrderForm();
        navigate("/list");
      },
    }
  );
  const cart = useAppSelector((state) => state.cart);

  const initialState: Partial<Order> = useMemo(
    () => ({
      customerName: "",
      customerNumber: "",
      customerEmail: "",
      salesForceId: "",
      user: undefined,
      products: [],
      _id: "",
    }),
    []
  );

  function reducer(
    state: typeof initialState,
    action: {
      type: "SET_VALUES" | "RESET_FORM" | "SET_ORDER";
      payload?: { id?: any; value: any };
    }
  ) {
    switch (action.type) {
      case "SET_VALUES":
        return {
          ...state,
          [action?.payload?.id]: action?.payload?.value,
        };
      case "RESET_FORM":
        return initialState;
      case "SET_ORDER":
        return action?.payload?.value;

      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(
    reducer,
    initialState,
    () => initialState
  );

  // const dispatchRdx = useAppDispatch();

  const user = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch({ type: "RESET_FORM" });
    if (currentOrder) {
      dispatch({ type: "SET_ORDER", payload: { value: currentOrder } });
    }
  }, [currentOrder, initialState, show]);

  useEffect(() => {
    schema
      .validate(state)
      .then(function (valid) {
        setFormErrors(undefined);
      })
      .catch(function (err) {
        console.log(err);

        setFormErrors(err);
      });
  }, [state]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "SET_VALUES",
      payload: { id: e.target.name, value: e.target.value },
    });
  };

  const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (formErrors || !user) {
      return;
    }
    const order: Order = {
      ...state,
      user: user.user?.id,
      products: cart.items,
      applicationId: "61019e1e7daa2fe722e156be",
    };
    try {
      if (order._id) {
        mutate(order);
        // const data = await patchOrder(order);
        // dispatchRdx({
        //   type: ordersConstants.UPDATE_ORDER,
        //   payload: {
        //     ...order,
        //     createdAt: data.createdAt,
        //     updatedAt: data.updatedAt,
        //   },
        // });
      } else {
        delete order._id;
        mutate(order);
      }

      dispatch({ type: "RESET_FORM" });
      setFormErrors(undefined);
      closeNewOrderForm();
    } catch (error) {
      console.log(error);
      toast.error("Failed to create order");
    }
  };
  return (
    <div>
      <Modal>
        <form
          onSubmit={onSubmit}
          className=" bg-white p-8 rounded-lg w-3/5 md:w-2/5 flex flex-col"
        >
          <div className="flex mb-4">
            <h1 className="font-bold text-5xl md:text-3xl lg:text-2xl xl:text-xl">
              {state._id ? "Edit Order" : "New Order Form"}
            </h1>

            <img
              className="ml-auto cursor-pointer w-14 md:w-12 lg:w-10 "
              onClick={() => {
                setFormErrors(undefined);
                dispatch({ type: "RESET_FORM" });
                // dispatchRdx({
                //   type: ordersConstants.SET_CURRENT_ORDER,
                //   payload: undefined,
                // });
                closeNewOrderForm();
              }}
              src="/assets/icons/close.svg"
              alt=""
            />
          </div>
          <p className="mb-2 text-4xl md:text-3xl lg:text-2xl xl:text-xl">
            Customer Name:{" "}
          </p>
          <input
            className=" px-4 py-4 text-3xl md:text-2xl lg:text-xl md:py-2 w-full focus:outline-none border mb-4"
            type="text"
            name="customerName"
            value={state.customerName}
            onChange={onInputChange}
          />
          <p className="mb-2 text-4xl md:text-3xl lg:text-2xl xl:text-xl">
            Customer Number:
          </p>
          <input
            className="px-4 py-4 text-3xl md:text-2xl lg:text-xl md:py-2 w-full focus:outline-none border mb-4"
            inputMode="tel"
            name="customerNumber"
            value={state.customerNumber}
            onChange={onInputChange}
          />

          {formErrors?.errors.map((err: string) => (
            <p
              key={err}
              className="text-red-500 text-4xl md:text-2xl lg:text-xl"
            >
              {err}
            </p>
          ))}
          <button className=" mt-8 md:mt-4 tracking-widest my-4 text-4xl md:text-2xl lg:text-xl py-8 md:py-3 px-8 bg-gray-900 hover:bg-black bg-customBlack-light text-white rounded-full font-bold transform transition-colors">
            {state._id ? "Save" : "Place order"}
          </button>
        </form>
      </Modal>
    </div>
  );
}
