import React from "react";
import { useAppSelector } from "../../hooks/redux";

export const Visualize = () => {
  const cart = useAppSelector((state) => state.cart);
  return (
    <div className="w-screen h-screen">
      <iframe
        className="w-full h-full"
        src={
          "https://ashirvad-visualizer.vercel.app/?products=" +
          cart.items.map((item) => item.product._id).join(",")
        }
        title="visualizer"
      ></iframe>
    </div>
  );
};
