import React, { useState } from "react";

interface Props {
  options: string[];
  onChange: (value: string) => void;
  value: string;
  className?: string;
}

export const Select = ({ options, onChange, value, className }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleSelection = (value: string) => {
    setIsOpen(false);
    onChange(value);
  };
  return (
    <div className={`relative ${className}`}>
      <h1
        onClick={() => setIsOpen(!isOpen)}
        className="border w-max p-4 px-6 rounded-full flex cursor-pointer"
      >
        <span className="font-bold mr-1">Sort: </span> {value}
        <img
          src="/assets/icons/down-arrow.svg"
          alt=""
          className="ml-3 w-9 md:w-8 lg:w-6"
        />
      </h1>

      {isOpen && (
        <div className="absolute top-9 left-0 bg-white mt-2 mr-2 rounded-md border">
          {options.map((option, index) => (
            <h1
              key={option}
              onClick={() => handleSelection(option)}
              className="py-2 px-4 border-b cursor-pointer"
            >
              {option}
            </h1>
          ))}
        </div>
      )}
    </div>
  );
};
