import { useCallback } from "react";
import {
  Page,
  Text,
  View,
  Image,
  Document,
  Font,
  PDFViewer,
} from "@react-pdf/renderer";
import { Order } from "../../types";
import toast from "react-hot-toast";
import { useOrders } from "../../api";
import { useParams } from "react-router";
import { useAppSelector } from "../../hooks/redux";
import { FileSharer } from "@byteowls/capacitor-filesharer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const Page1 = ({ order }: { order?: Order }) => {
  return (
    <Page
      size="A4"
      style={{
        backgroundColor: "white",
        padding: 30,
        fontFamily: "Open Sans",
        fontSize: 13,
        width: "100%",
      }}
    >
      <View
        style={{
          color: "black",
          display: "flex",
          flexDirection: "row",
          paddingVertical: 20,
          borderBottom: "2 solid #9a9a9a",
          marginBottom: 30,
          alignItems: "center",
          backgroundColor: "#e4141e",
          paddingHorizontal: 20,
        }}
      >
        <Text
          style={{
            fontWeight: "bold",
            fontSize: 18,
            color: "white",
          }}
        >
          Quotation
        </Text>
        <Image
          src="/assets/logo.png"
          style={{
            height: 30,
            marginLeft: "auto",
            marginTop: "auto",
          }}
        ></Image>
      </View>
      <View style={{ display: "flex", flexDirection: "row", marginBottom: 10 }}>
        <Text style={{ fontSize: 13, fontWeight: "bold" }}>
          Dear {order?.customerName},
        </Text>
        <Text style={{ marginLeft: "auto", fontWeight: "bold" }}>
          {order?.salesForceId}
        </Text>
      </View>
      <View>
        <Text style={{ marginBottom: 40 }}>
          Here is the quotation for the products you have requested for.
        </Text>
      </View>
      <View style={{ border: "2px solid #dddddd", borderRadius: 10 }}>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            padding: 20,
            borderBottom: "2px solid #dddddd",
          }}
        >
          <Text style={{ width: "15%" }}>Sl.No</Text>
          <Text style={{ width: "45%", textAlign: "left" }}>Product</Text>
          <Text style={{ width: "20%", textAlign: "center" }}>Quantity</Text>
          <Text style={{ width: "20%", textAlign: "right" }}>Price</Text>
        </View>
        {order?.products.map(({ product, variant, quantity }, index) => (
          <View
            key={product._id}
            style={{
              display: "flex",
              flexDirection: "row",
              padding: 20,

              borderBottom:
                order.products.length - 1 === index ? "" : "2px solid #dddddd",
            }}
          >
            <Text style={{ width: "15%" }}>{index + 1}</Text>
            <View
              style={{
                display: "flex",
                flexDirection: "row",

                width: "45%",
                textAlign: "left",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                // paddingHorizontal: 50,
                paddingRight: 50,
              }}
            >
              {product.thumbnailUrl && (
                <Image
                  src={variant?.thumbnailUrl || product.thumbnailUrl || ""}
                  style={{ width: 70 }}
                ></Image>
              )}
              <Text>{product.name}</Text>
            </View>
            <Text style={{ width: "20%", textAlign: "center" }}>
              {quantity}
            </Text>
            <Text
              style={{
                width: "20%",
                textAlign: "right",
              }}
            >
              {"Rs " + product?.price}
            </Text>
          </View>
        ))}
      </View>
      <View>
        <Text
          style={{
            textAlign: "right",
            fontWeight: "bold",
            marginTop: 10,
          }}
        >
          Total Price:{" Rs "}
          {order?.products.reduce(
            (acc, curr) => acc + (curr?.product.price || 0),
            0
          )}
        </Text>
      </View>
      <View
        style={{
          marginTop: "auto",
          paddingTop: 20,
          borderTop: "2 solid #9a9a9a",
        }}
      >
        <Text>
          Disclaimer - Lorem ipsum dolor sit amet. Aut explicabo debitis est
          recusandae cupiditate in delectus magni iste ipsa. Ut quae adipisci 33
          inventore praesentium ut perspiciatis enim sed consectetur eligendi.
        </Text>
      </View>
    </Page>
  );
};

const MyDocument = ({ order }: { order?: Order }) => (
  <Document>
    <Page1 order={order} />
  </Document>
);

const Header = ({ onDownload }: { onDownload: () => void }) => {
  return (
    <div
      style={{ height: "max-content" }}
      className="w-full flex items-center px-4 py-4 justify-center"
    >
      <div className="flex ml-auto">
        <button className="bg-gray-200 w-11 h-11 mr-8 flex items-center justify-center px-3 py-3 rounded-full">
          <img src="/assets/icons/download.svg" onClick={onDownload} alt="" />
        </button>
      </div>
    </div>
  );
};

export const Quote = () => {
  const session = useAppSelector((state) => state.user);
  const { data: orders } = useOrders(session.user?.id);
  const { id: orderId } = useParams();

  const downloadPDF = useCallback(async () => {
    const pdfContainer = document.getElementById("pdf-container");
    const pdfSource = pdfContainer?.querySelector("iframe")?.src;
    if (!pdfSource) {
      toast.error("PDF not found");
      return;
    }
    const pdfBlob = await fetch(pdfSource).then((res) => res.blob());
    console.log(pdfBlob);

    const blobToBase64 = (blob: Blob): Promise<string | ArrayBuffer | null> => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };

    const base64 = await blobToBase64(pdfBlob);
    if (typeof base64 !== "string") {
      return;
    }

    FileSharer.share({
      filename: "quote.pdf",
      base64Data: base64.split(",")[1],
      contentType: "application/pdf",
    })
      .then(() => {
        // do sth
      })
      .catch((error) => {
        console.error("File sharing failed", error.message);
      });
  }, []);

  return (
    <div className="flex flex-col h-full">
      <Header onDownload={downloadPDF} />
      <div
        className="h-full flex-grow flex justify-center w-full"
        id="pdf-container"
      >
        <PDFViewer width="100%" height="100%">
          <MyDocument order={orders?.find((o) => o._id === orderId)} />
        </PDFViewer>
      </div>
    </div>
  );
};
