import { useQuery } from "react-query";
import { Category, Product, Collection, Order } from "../types";

const CreateQueryHook = <T>(endpoint: string) => {
  const { data, isLoading, error } = useQuery<T>(endpoint, () =>
    fetch(process.env.REACT_APP_API_ENDPOINT + endpoint)
      .then((res) => res.json())
      .then((res) => res._items)
  );

  return {
    data,
    isLoading,
    error,
  };
};

export const useProducts = () => {
  const embed = encodeURIComponent(
    JSON.stringify({ category: 1, collection: 1 })
  );
  return CreateQueryHook<Product[]>(
    "content/products?application=companion&max_results=1000&&embedded=" + embed
  );
};

export const useCategories = () => {
  return CreateQueryHook<Category[]>(
    "content/categories?application=companion&sort=" +
      encodeURI('[("sortOrder", 1)]')
  );
};

export const useCollections = () => {
  return CreateQueryHook<Collection[]>(
    "content/collections?application=companion&sort=" +
      encodeURI('[("sortOrder", 1)]')
  );
};

export const useOrders = (userId?: string) => {
  const whereObj = {
    user: userId,
  };
  const whereClause = encodeURI(JSON.stringify(whereObj));

  const embeddedObj = { "user": 1 };
  const embeddedClause = encodeURI(JSON.stringify(embeddedObj));

  return CreateQueryHook<Order[]>(
    "content/orders?embedded=" +
      embeddedClause +
      (userId ? "&where=" + whereClause : "")
  );
};
