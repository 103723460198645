import React from "react";
import { useNavigate } from "react-router";
import { useCategories } from "../../api";
import { useAppDispatch } from "../../hooks/redux";
import { clearFilter, setSelectedCategories } from "../../store/globalState";

export const Categories = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data } = useCategories();

  const handleClick = (category: string) => {
    dispatch(clearFilter());
    dispatch(setSelectedCategories(category));
    navigate(`/list`);
  };

  return (
    <div className="flex flex-col items-center w-full h-full">
      <div className="mb-auto mt-auto w-full">
        <h1 className="text-4xl text-center">Our Products</h1>
        <div className="grid grid-cols-3 w-full  gap-8 p-8">
          {data?.map((category) => (
            <div
              key={category._id}
              className="flex flex-col items-center mb-14"
            >
              <img src={category.thumbnailUrl} className="mb-4 w-full" alt="" />
              <h1 className="text-4xl mb-4">{category.name}</h1>
              <h1 className="text-sm mb-4 text-center">
                {category.description}
              </h1>
              <button
                onClick={() => handleClick(category.name)}
                className="w-max border-2 border-black rounded-full px-4 py-2"
              >
                VIEW PRODUCTS
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
