import { Storage } from "@capacitor/storage";
import { User } from "../types";

const USER_KEY = "user";

export const getUserFromLocalStorage = async (): Promise<User | null> => {
  const x = (await Storage.get({ key: USER_KEY })).value;
  return x ? JSON.parse(x) : null;
};

export const saveUserToLocalStorage = (user: User) => {
  return Storage.set({
    key: USER_KEY,
    value: JSON.stringify(user),
  });
};

export const removeUserFromLocalStorage = () => {
  return Storage.remove({ key: USER_KEY });
};
