import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useOrders } from "../../api";
import { Select } from "../../Components/Select";
import { useAppSelector } from "../../hooks/redux";

const sortOptions = ["Recent", "Completed"];

export const Orders = () => {
  const [sortBy, setSortBy] = useState(sortOptions[0]);
  const session = useAppSelector((state) => state.user);
  const { data: orders } = useOrders(session.user?.id);
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-8">
      <div className="flex p-8">
        <h1 className="text-3xl font-bold mr-auto">All Orders</h1>
        <Select
          options={sortOptions}
          onChange={(x) => setSortBy(x)}
          value={sortBy}
        />
      </div>
      <table className="w-full mt-8 mx-8">
        <thead>
          <tr>
            <th className="text-left border-b pb-7">CUSTOMER NAME</th>
            <th className="text-left border-b pb-7">EMAIL</th>
            <th className="text-left border-b pb-7">SALES FORCE ID</th>
            <th className="text-left border-b pb-7">CREATED AT</th>
            <th className="text-left border-b pb-7">SPECS</th>
            <th className="text-left border-b pb-7">QUOTE</th>
          </tr>
        </thead>
        <tbody>
          {[...(orders || [])].reverse().map((order) => (
            <tr key={order._id}>
              <td className="text-left border-b py-7">{order.customerName}</td>
              <td className="text-left border-b py-7">{order.customerEmail}</td>
              <td className="text-left border-b py-7">{order.salesForceId}</td>
              <td className="text-left border-b py-7">
                {new Date(order.createdAt || "").toLocaleDateString()}
              </td>
              <td className="text-center border-b py-7">
                <img src="/assets/icons/specs.png" alt="" />
              </td>
              <td className="text-left border-b py-7">
                <img
                  onClick={() => navigate("/quote/" + order._id)}
                  src="/assets/icons/send.svg"
                  alt=""
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
