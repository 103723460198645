import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router";
import { useAppDispatch } from "../../hooks/redux";
import { setUser } from "../../store/globalState";
import { User } from "../../types";
import appLandscapeImage from "../../assets/images/ashirvadLandscape.png";
import appPortraitImage from "../../assets/images/ashirvadPortrait.png";
import "./login.css";

import {
  getUserFromLocalStorage,
  saveUserToLocalStorage,
} from "../../utils/localStorage";

interface IProps {
  isPortrait: boolean;
}

const Login: React.FC<IProps> = ({ isPortrait }) => {
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loginType, setLoginType] = useState("phone");
  const [response, setResponse] = useState<{ requestId: string } | undefined>();
  const [otp, setOtp] = useState("");
  const [generatingOtp, setGeneratingOtp] = useState(false);
  const [verifyingOtp, setVerifyingOtp] = useState(false);
  const [showImage, setShowImage] = useState(true);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const onLogin = useCallback(
    (user: User) => {
      saveUserToLocalStorage(user);
      dispatch(setUser(user));
      navigate("/explore");
    },
    [dispatch, navigate]
  );

  useEffect(() => {
    (async () => {
      const user = await getUserFromLocalStorage();
      if (user) {
        onLogin(user);
      }
    })();
  }, [onLogin]);

  useEffect(() => {
    setShowImage(true);
    const timer = setTimeout(() => {
      setShowImage(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const sendOtp = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (
      loginType === "email" &&
      !email.match(/^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/)
    ) {
      toast.error("Please enter a valid email address");
      return;
    }
    if (
      loginType === "phone" &&
      phoneNumber.match(/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/)
    ) {
      toast.error("Please enter a valid phone number with country code");
      return;
    }
    setGeneratingOtp(true);
    if (loginType === "phone") {
      console.log(loginType);
      axios
        .post(process.env.REACT_APP_API_ENDPOINT + "login", {
          email: phoneNumber,
        })
        .then((res) => {
          setResponse(res.data);
          setGeneratingOtp(false);
        })
        .catch((err) => {
          toast.error("User not found");
          setGeneratingOtp(false);
        });
    } else {
      console.log(loginType);
      axios
        .post(process.env.REACT_APP_API_ENDPOINT + "login", { email })
        .then((res) => {
          setResponse(res.data);
          setGeneratingOtp(false);
        })
        .catch((err) => {
          toast.error("User not found");
          setGeneratingOtp(false);
        });
    }
  };

  const verifyOtp = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    if (!response?.requestId) {
      return;
    }
    setVerifyingOtp(true);
    axios
      .post(process.env.REACT_APP_API_ENDPOINT + "verify_otp", {
        requestId: response.requestId,
        otp,
        email,
      })
      .then((res) => {
        onLogin({ ...res.data.user, token: res.data.token });
        setVerifyingOtp(false);
        setResponse(undefined);
        setOtp("");
      })
      .catch((err) => {
        toast.error("Invalid OTP");
        setVerifyingOtp(false);
      });
  };

  return (
    <div
      className={`w-screen h-screen ${
        isPortrait ? "loginPortrait" : "loginLandscape"
      }`}
    >
      <div className="sm:px-16 px-4 h-screen flex items-center flex-col bg-blur">
        {showImage ? (
          <div className="w-screen h-screen flex justify-center align-middle">
            <img
              src={isPortrait ? appPortraitImage : appLandscapeImage}
              alt="Artistry"
              className="object-cover"
            />
          </div>
        ) : (
          <div className="flex flex-grow flex-wrap flex-col w-full justify-center items-center">
            {response?.requestId ? (
              <form
                onSubmit={verifyOtp}
                style={{ height: "max-content" }}
                className={`bg-white sm:p-8 md:p-16 md:pt-0 p-4 flex flex-col shadow-lg rounded-2xl items-center justify-between ${
                  isPortrait ? "w-10/12 md:w-3/5" : "w-10/12 md:w-1/2"
                }`}
              >
                <div className="flex  justify-center py-4 mb-6 flex-grow-0">
                  <img src="assets/logo.png" className="h-16" alt="" />
                </div>
                <h3
                  className={`font-bold mb-4 text-center ${
                    isPortrait ? "text-6xl md:text-4xl" : "text-2xl"
                  }`}
                >
                  OTP sent to <br />{" "}
                  {loginType === "phone" ? phoneNumber : email}
                </h3>
                <h4
                  className={
                    isPortrait ? "text-4xl md:text-2xl mt-4 mb-4" : "text-xl"
                  }
                >
                  Can you please verify it?
                </h4>
                <OtpInput
                  inputStyle={`border font-bold rounded-sm focus:outline-none otp-input ${
                    isPortrait ? " text-8xl md:text-6xl" : " text-2xl"
                  }`}
                  containerStyle="my-4"
                  numInputs={4}
                  separator={<span className="mx-3">-</span>}
                  value={otp}
                  onChange={(otp: string) => setOtp(otp)}
                  isInputNum={true}
                />
                <h3
                  onClick={sendOtp}
                  className={`w-max cursor-pointer ml-auto mr-auto mb-8 mt-4 hover:text-customRed underline font-bold ${
                    isPortrait ? "text-4xl md:text-3xl" : "text-xl"
                  }`}
                >
                  {generatingOtp ? "Generating OTP..." : "Resend OTP"}
                </h3>
                <button
                  onClick={verifyOtp}
                  style={{
                    backgroundColor: verifyingOtp ? "#8A3A22" : "#8A3A22",
                  }}
                  className={`${
                    verifyingOtp ? "text-gray-300" : "text-white"
                  } font-bold rounded-full ${
                    isPortrait
                      ? "text-4xl w-1/2 py-8"
                      : "text-2xl w-full px-4 py-4"
                  }`}
                >
                  {verifyingOtp ? "Verifying OTP..." : "Confirm"}
                </button>
                <h3
                  onClick={() => setResponse(undefined)}
                  className={`cursor-pointer w-max ml-auto mr-auto mt-8 hover:text-customRed underline font-bold ${
                    isPortrait
                      ? " text-4xl md:text-3xl mb-10 md:mb-auto"
                      : " text-xl  mb-0"
                  }`}
                >
                  Use different email address
                </h3>
              </form>
            ) : (
              <div className="flex flex-col justify-center items-center bg-white rounded-2xl w-10/12 md:w-3/5 shadow-lg">
                <div className="flex justify-between items-center w-full px-16 mt-8">
                  <h1
                    className={`px-4 py-6 mt-8 font-bold rounded-full ${
                      isPortrait
                        ? "text-4xl "
                        : "text-5xl md:text-2xl md:h-auto"
                    } ${loginType === "phone" && "text-customBtn underline"}`}
                    onClick={() => setLoginType("phone")}
                  >
                    Phone Number
                  </h1>
                  <h1
                    className={`px-4 py-6 mt-8 font-bold rounded-full ${
                      isPortrait ? "text-4xl" : "text-5xl md:text-2xl md:h-auto"
                    } ${loginType === "email" && "text-customBtn underline"}`}
                    onClick={() => setLoginType("email")}
                  >
                    Email Address
                  </h1>
                </div>
                {loginType === "phone" ? (
                  <form
                    onSubmit={sendOtp}
                    style={{ height: "max-content" }}
                    className={`sm:p-8 md:p-16 md:pt-0 px-8 mt-4 flex flex-col justify-between w-full`}
                  >
                    {/* <div className="flex justify-center py-4 mb-6 flex-grow-0">
                      <img src="assets/logo.png" className="h-16" alt="" />
                    </div> */}
                    <h3
                      className={`mb-8 md:mb-4 ${
                        isPortrait
                          ? "text-5xl md:text-4xl lg:text-2xl"
                          : "text-6xl md:text-2xl"
                      }`}
                    >
                      Enter your phone number with country code +91
                    </h3>
                    <input
                      style={{ borderColor: "#3183ff" }}
                      className={`border rounded-full mb-4 py-2 focus:outline-none  ${
                        isPortrait
                          ? "text-5xl md:text-4xl h-24 px-14"
                          : "text-5xl md:text-xl h-24 md:h-auto px-10 md:px-4"
                      }`}
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <button
                      onClick={sendOtp}
                      disabled={generatingOtp}
                      style={{
                        backgroundColor: generatingOtp ? "#8A3A22" : "#8A3A22",
                      }}
                      className={`${
                        generatingOtp ? "text-gray-300" : "text-white"
                      } px-4 py-2 mt-8 mb-10  font-bold rounded-full ${
                        isPortrait
                          ? "text-4xl h-24"
                          : "text-5xl md:text-2xl h-24 md:h-auto"
                      }`}
                    >
                      {generatingOtp ? "Generating OTP..." : "Next"}
                    </button>
                  </form>
                ) : (
                  <form
                    onSubmit={sendOtp}
                    style={{ height: "max-content" }}
                    className={`sm:p-8 md:p-16 md:pt-0 px-8 mt-4 flex flex-col justify-between w-full`}
                  >
                    {/* <div className="flex justify-center py-4 mb-6 flex-grow-0">
                      <img src="assets/logo.png" className="h-16" alt="" />
                    </div> */}
                    <h3
                      className={`mb-8 md:mb-4 ${
                        isPortrait
                          ? "text-5xl md:text-4xl lg:text-2xl"
                          : "text-6xl md:text-2xl"
                      }`}
                    >
                      Enter your email
                    </h3>
                    <input
                      style={{ borderColor: "#3183ff" }}
                      className={`border rounded-full mb-4 py-2 focus:outline-none  ${
                        isPortrait
                          ? "text-5xl md:text-4xl h-24 px-14"
                          : "text-5xl md:text-xl h-24 md:h-auto px-10 md:px-4"
                      }`}
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button
                      onClick={sendOtp}
                      disabled={generatingOtp}
                      style={{
                        backgroundColor: generatingOtp ? "#8A3A22" : "#8A3A22",
                      }}
                      className={`${
                        generatingOtp ? "text-gray-300" : "text-white"
                      } px-4 py-2 mt-8 mb-10  font-bold rounded-full ${
                        isPortrait
                          ? "text-4xl h-24"
                          : "text-5xl md:text-2xl h-24 md:h-auto"
                      }`}
                    >
                      {generatingOtp ? "Generating OTP..." : "Next"}
                    </button>
                  </form>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
