import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { logout } from "../store/globalState";
import { removeUserFromLocalStorage } from "../utils/localStorage";
import { registerPlugin, Capacitor } from "@capacitor/core";

const HelloIFrame: any = registerPlugin("HelloIFrame");

interface Props {
  children: React.ReactNode;
  isPortrait: boolean;
}

const noHeaderPages = ["/"];

export const Header = ({ children, isPortrait }: Props) => {
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();
  const search = useAppSelector((state) => state.productFilter.search);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const cartItemCount = useAppSelector((state) =>
    state.cart.items.reduce((acc, item) => acc + item.quantity, 0)
  );
  const dispatch = useAppDispatch();
  const platform = Capacitor.getPlatform();
  useEffect(() => {
    if (search.length > 0) {
      headerRef.current?.scrollIntoView({ behavior: "smooth" });
      headerRef.current?.querySelector("input")?.focus();
    }
    return () => {};
  }, [search.length]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (showMenu && !event.target.closest("#menu")) {
        setShowMenu(false);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [showMenu]);

  const logOut = () => {
    setShowMenu(false);
    removeUserFromLocalStorage();
    dispatch(logout());
    navigate("/");
  };

  const showVisualizer = async () => {
    await HelloIFrame.showurl();
  };

  const menuItems = useMemo(() => {
    return [
      {
        name: "Orders",
        onClick: () => {
          navigate("/orders");
          setShowMenu(false);
        },
      },
      {
        name: "Customers",
        onClick: () => null,
      },
    ];
  }, [navigate]);

  return (
    <div className="w-full h-screen">
      {!noHeaderPages.includes(window.location.pathname) && (
        <div
          style={{
            height: "max-content",
            backgroundColor: "#8A3A22",
            // top: scrollPosition,
          }}
          className={`w-full flex items-center px-8 sticky top-0 z-20 ${
            isPortrait ? "py-20 md:py-8" : "pt-8 pb-4"
          }`}
          ref={headerRef}
        >
          {!(window.location.pathname === "/explore") && (
            <>
              <img
                onClick={() => window.history.back()}
                src="/assets/icons/left-arrow.svg"
                className="w-16 md:w-8  fill-current cursor-pointer"
                alt=""
              />
              <img
                src="/assets/icons/home.png"
                className=" ml-5 w-16 md:w-10  object-contain mb-0"
                alt=""
                onClick={() => navigate("/explore")}
              />
            </>
          )}
          <div
            style={{ zIndex: -10 }}
            className={`w-full absolute left-0 flex ${
              window.location.pathname === "/explore"
                ? "justify-start pl-6"
                : "justify-start pl-48 md:pl-36"
            }`}
          >
            <img
              src="/assets/logo.svg"
              className="h-28 md:h-14  mb-0"
              alt="logo"
            />
          </div>
          <div className="flex ml-auto items-center">
            {platform === "ios" && (
              <div
                onClick={() => showVisualizer()}
                className="flex items-center"
              >
                <img
                  src="/assets/icons/cube.png"
                  className="w-16 md:w-9 mr-1 object-contain"
                  alt=""
                />
                <h1
                  className={`text-white font-bold ml-1 ${
                    isPortrait ? "text-4xl md:text-2xl" : " text-xl"
                  }`}
                >
                  Visualize in your bathrooms
                </h1>
                <div
                  style={{ width: "2px" }}
                  className=" h-6 mx-8 my-4 bg-white"
                />
              </div>
            )}
            <div className="relative">
              {cartItemCount > 0 && (
                <h1 className="text-xl text-white font-bold absolute -right-3 -top-3">
                  {cartItemCount}
                </h1>
              )}
              <img
                onClick={() => navigate("/cart")}
                src="/assets/icons/cart.svg"
                className="w-16 md:w-8 object-contain"
                alt=""
              />
            </div>

            <div style={{ width: "2px" }} className=" h-6 mx-8 my-4 bg-white" />
            <img
              src="/assets/icons/user.png"
              className="w-16 md:w-8 object-contain"
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setShowMenu(!showMenu);
              }}
            />
          </div>
          {showMenu && (
            <div
              style={{
                backgroundColor: "#F4F4FC",
                top: `${headerRef.current?.clientHeight}px`,
              }}
              className="bg-white menu md:w-72 absolute right-0 text-2xl p-8 shadow-lg"
              id="menu"
            >
              <ul>
                {menuItems.map((item, index) => (
                  <li
                    key={item.name}
                    onClick={item.onClick}
                    className="cursor-pointer text-5xl md:text-2xl"
                  >
                    <h1
                      style={{ borderColor: "#E2E2E2" }}
                      className={`border-b p-4`}
                    >
                      {item.name}
                    </h1>
                  </li>
                ))}
              </ul>
              <button
                onClick={logOut}
                style={{ backgroundColor: "#8A3A22" }}
                className="px-6 py-4 md:py-2 text-white mt-4 w-full text-5xl md:text-2xl"
              >
                Log Out
              </button>
            </div>
          )}
        </div>
      )}
      <div
        className="overflow-scroll "
        style={{
          height: !noHeaderPages.includes(window.location.pathname)
            ? `calc(100vh - ${headerRef.current?.clientHeight}px)`
            : "h-screen",
        }}
      >
        {children}
      </div>
    </div>
  );
};
