import React from "react";
import { useParams } from "react-router-dom";
import { useProducts } from "../../api";
import getExtraParam from "../../utils/getExtraParam";

export const Specifications = () => {
  const { id } = useParams();
  const { data: products } = useProducts();
  const product = products?.find((p) => p._id === id);
  // const specImage = product?.extraParams?.filter(
  //   (item) => item.key === "spec_image"
  // )[0].value;

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="p-8 flex-grow">
        <h1 className="text-5xl md:text-3xl font-bold mb-8 text-gray-700">
          Specifications
        </h1>
        <div className="flex items-center mb-4">
          <h1 className="text-6xl md:text-4xl font-bold">{product?.name}</h1>
          {product?.extraParams && (
            <a
              href={getExtraParam(product, "spec_image")}
              target="_blank"
              rel="noreferrer"
              download
              className="text-3xl px-4 py-4 ml-5 rounded-full bg-customBtn text-white"
            >
              Download
            </a>
          )}
        </div>
        <div className="w-full h-full flex">
          <div className=" w-3/4 p-8 flex justify-center">
            <img
              src={getExtraParam(product, "spec_image")}
              className=" h-1/2 object-contain"
              alt=""
            />
          </div>
          {/* <div className="w-1/4 p-8 flex flex-col">
            {product?.specifications.map((spec) => (
              <h1 key={spec} className="my-4 text-3xl font-bold">
                {spec}
              </h1>
            ))}
          </div> */}
        </div>
      </div>
    </div>
  );
};
