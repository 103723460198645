import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useAppSelector } from "../../hooks/redux";
import {
  addItemToCart,
  clearCart,
  removeItemFromCart,
} from "../../store/globalState";
import { CartItem } from "../../types";
import OrderForm from "./OrderForm";
import { Capacitor } from "@capacitor/core";

interface IProps {
  isPortrait: boolean;
}

export const Cart: React.FC<IProps> = ({ isPortrait }) => {
  const platform = Capacitor.getPlatform();
  const [showOrderForm, setShowOrderForm] = useState(false);

  const cart = useAppSelector((state) => state.cart);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const addProductToCart = (cartItem: CartItem, quantity = 1) => {
    if (cartItem) {
      dispatch(
        addItemToCart({
          id: cartItem.product._id + "-" + cartItem.variant?.name,
          product: cartItem.product,
          quantity,
        })
      );
    }
  };

  const emptyCart = () => {
    dispatch(clearCart());
  };

  return (
    <div className="mt-16">
      {showOrderForm && (
        <OrderForm
          show={true}
          closeNewOrderForm={() => setShowOrderForm(false)}
          currentOrder={null}
          clearCart={emptyCart}
        />
      )}
      <div
        className={`flex ${isPortrait ? "flex-col md:flex-row" : "flex-row"}`}
      >
        <div className="p-8 w-full md:w-3/4">
          <table className="w-full">
            <thead>
              <tr>
                <th className="text-left pb-8 text-5xl md:text-3xl lg:text-2xl">
                  Product
                </th>
                <th className="pb-8 text-center px-4 text-5xl md:text-3xl lg:text-2xl">
                  Price
                </th>
                <th className="pb-8 text-center px-4 text-5xl md:text-3xl lg:text-2xl">
                  Quantity
                </th>
                <th className="pb-8 text-center px-4 text-5xl md:text-3xl lg:text-2xl">
                  Total
                </th>
              </tr>
            </thead>
            <tbody>
              {cart.items.map((item, index) => (
                <>
                  <tr key={index}>
                    <td className="text-left flex justify-start">
                      <div className="relative mr-4">
                        <img
                          src={
                            item.variant?.thumbnailUrl ||
                            item.product.thumbnailUrl
                          }
                          className="w-48 md:w-24 "
                          alt=""
                        />
                        <img
                          className="absolute right-0 top-0 cursor-pointer"
                          src="/assets/icons/x-circle.svg"
                          alt=""
                          onClick={() => dispatch(removeItemFromCart(item.id))}
                        />
                      </div>
                      <div className="text-4xl md:text-3xl lg:text-2xl">
                        <h1 className="font-bold">{item.product.name}</h1>
                        {/* <h1>Color: {item.variant?.name}</h1> */}
                      </div>
                    </td>
                    <td className="text-center align-top text-4xl md:text-3xl lg:text-xl">
                      <h1>
                        {item.product.price === 0
                          ? "NA"
                          : `₹${item.product.price}`}
                      </h1>
                    </td>
                    <td className="text-center align-top">
                      <div className="flex justify-center h-full text-4xl md:text-3xl lg:text-xl">
                        <h1
                          onClick={() => addProductToCart(item, -1)}
                          className="bg-gray-200 w-10 md:w-5 "
                        >
                          -
                        </h1>
                        <h1 className="px-4 bg-gray-100">{item.quantity}</h1>
                        <h1
                          onClick={() => addProductToCart(item)}
                          className="bg-gray-200 w-10 md:w-5 text-4xl md:text-3xl lg:text-xl"
                        >
                          +
                        </h1>
                      </div>
                    </td>
                    <td className="text-center align-top text-4xl md:text-3xl lg:text-xl">
                      <h1>
                        {item.product.price === 0
                          ? "NA"
                          : `₹${item.quantity * item.product.price}`}
                      </h1>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <div className="border-b pb-4 h-px w-full"></div>
                    </td>
                    <td>
                      <div className="border-b pb-4 h-px w-full"></div>
                    </td>
                    <td>
                      <div className="border-b pb-4 h-px w-full"></div>
                    </td>
                    <td>
                      <div className="border-b pb-4 h-px w-full"></div>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
          {cart.items.length === 0 ? (
            <div className="text-center mt-16 text-xl text-gray-500">
              Cart is empty
            </div>
          ) : (
            <div className="flex mt-8">
              {platform === "ios" && (
                <button
                  onClick={() => navigate("/visualize")}
                  className="text-white px-8 py-8 md:px-4 md:py-4 text-5xl md:text-3xl lg:text-xl"
                  style={{ backgroundColor: "#3183FF" }}
                >
                  Visualize Cart in Bathroom Space
                </button>
              )}
              <button
                className=" px-8 py-8 md:py-4 ml-auto text-5xl md:text-3xl lg:text-xl"
                style={{ backgroundColor: "#E7E7EF" }}
                onClick={emptyCart}
              >
                Clear Cart
              </button>
            </div>
          )}
        </div>
        <div
          style={{
            // width: "30%",
            backgroundColor: "#F4F4FC",
            height: "max-content",
          }}
          className="p-8 mx-8 md:w-1/3 mt-8 md:mt-0"
        >
          <h1 className="font-bold mb-8 text-4xl md:text-3xl lg:text-xl">
            Cart Totals
          </h1>
          <div className="text-4xl md:text-3xl lg:text-lg">
            <div className="flex justify-start border-b pb-2 mb-4">
              <h1 className="font-bold">Subtotal:</h1>
              <h1 className="ml-auto">
                {cart.total === 0 ? "NA" : `₹${cart.total}`}
              </h1>
            </div>
            <div className="flex justify-start border-b pb-2 mb-4">
              <h1 className=" font-bold">Total:</h1>
              <h1 className="ml-auto">
                {cart.total === 0 ? "NA" : `₹${cart.total}`}
              </h1>
            </div>

            <h1 className="text-2xl md:text-lg text-gray-500 mb-8">
              Shipping & taxes calculated at checkout
            </h1>
            <button
              className="text-white w-full px-8 py-8 md:px-4 md:py-4 text-5xl md:text-3xl lg:text-xl"
              style={{
                backgroundColor:
                  cart.items.length === 0 || cart.total === 0
                    ? "#ffb8a2"
                    : "#8A3A22",
              }}
              disabled={cart.items.length === 0 || cart.total === 0}
              onClick={() => setShowOrderForm(true)}
            >
              Proceed To Checkout
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
