import { useState } from "react";
import { useNavigate, useParams } from "react-router";
import { addItemToCart } from "../../store/globalState";
import toast from "react-hot-toast";
import { CartItemCount } from "./CartItemCount";
import { useProducts } from "../../api";
import getExtraParam from "../../utils/getExtraParam";
import { registerPlugin } from "@capacitor/core";
import { useAppDispatch } from "../../hooks/redux";
import { Capacitor } from "@capacitor/core";

const HelloQuickLook: any = registerPlugin("HelloQuickLook");

interface IProps {
  isPortrait: boolean;
}

export const ProductDetails: React.FC<IProps> = ({ isPortrait }) => {
  const { id } = useParams();
  const [itemCount, setItemCount] = useState(1);
  const { data: products } = useProducts();
  const product = products?.find((p) => p._id === id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [currentVariant, setCurrentVariant] = useState(product?.variants[0]);
  const [showColorOptions, setShowColorOptions] = useState(false);

  const colors = [
    { name: "Black", hexCode: "#000000" },
    { name: "Matte Blue", hexCode: "#0d5c82" },
    { name: "Onyx Gold", hexCode: "#b28c5f" },
    { name: "Light Gold", hexCode: "#ab9864" },
    { name: "Copper", hexCode: "#B28C5E" },
    { name: "Matte Black", hexCode: "#141313" },
    { name: "Matte Gun Metal", hexCode: "#6c6c6c" },
    { name: "Matte Chocolate", hexCode: "#59483a" },
    { name: "Matte Steel", hexCode: "#e7e6e5" },
    { name: "Matte Gold", hexCode: "#b9a46a" },
    { name: "Icy White", hexCode: "#98a2ab" },
    { name: "Black Gold", hexCode: "#514f4f" },
    { name: "Rose Gold", hexCode: "#d6a58e" },
    { name: "Chocolate", hexCode: "#483a2a" },
    { name: "Gold", hexCode: "#a99254" },
    { name: "Glossy Blue", hexCode: "#0D5C83" },
    { name: "Chrome", hexCode: "#C0C0C0" },
  ];

  const showInAR = async () => {
    await HelloQuickLook.showInAR({
      usdzURL:
        currentVariant?.threeDeeModel?.usdz || product?.threeDeeModel?.usdz,
    });
  };

  const platform = Capacitor.getPlatform();

  const addProductToCart = () => {
    if (itemCount <= 0) {
      toast.error("Please select a valid quantity");
      return;
    }

    if (product) {
      if (!product._id) {
        toast.error("Product not found");
        return;
      }
      dispatch(
        addItemToCart({
          id: product._id + "-" + currentVariant?.name,
          product: product as any,
          quantity: itemCount,
          variant: currentVariant,
        })
      );
      toast.success("Product added to cart");
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div
        className={`flex  flex-grow w-full p-16 ${
          isPortrait ? "flex-col justify-center" : "h-full"
        }`}
      >
        <div
          style={
            isPortrait
              ? { width: "100%" }
              : { width: "68%", marginRight: "2%", height: "83vh" }
          }
          // className={` ${
          //   platform === "android" ? "flex items-baseline h-full" : "h-full"
          // }`}
          className={`grid h-full items-center`}
        >
          <img
            className="h-auto w-full"
            src={
              currentVariant?.thumbnailUrl ||
              product?.thumbnailUrl ||
              "/assets/placeholder.jpg"
            }
            alt=""
          />
          {/* <iframe
            className="h-full w-full"
            src={
              "https://helloviewer.io/?transparent=true&id=" +
              (currentVariant?.helloARProductId || product?.helloARProductId)
            }
            title="viewer"
          ></iframe> */}
        </div>

        {isPortrait ? (
          <div className="w-full flex flex-col ">
            <div className="flex mb-8">
              <h1
                className={`font-bold text-6xl md:text-5xl ${
                  platform === "ios" ? "w-1/2" : "w-full"
                }`}
              >
                {product?.name}
              </h1>
              {(currentVariant?.threeDeeModel?.usdz ||
                product?.threeDeeModel?.usdz) &&
                platform === "ios" && (
                  <div className="flex">
                    <button
                      onClick={showInAR}
                      className="ml-10 flex mr-4 mt-auto bg-customBtn items-center text-white px-5 py-3 rounded-full"
                    >
                      <h1 className="text-4xl md:text-3xl ">View Live</h1>
                    </button>
                  </div>
                )}
            </div>
            <div className="flex">
              <div className=" w-1/2">
                <h1 className="text-4xl mb-4 pt-8">
                  {product?.price === 0
                    ? "Price on request"
                    : product && `₹${product?.price}`}
                </h1>
                <div className="w-full h-px bg-gray-300 mb-8" />
                <h1 className="text-4xl md:text-3xl">
                  Collection:{" "}
                  <span>{product?.collection.name.split("+")[0] || ""}</span>
                </h1>
                <h1 className="text-4xl md:text-3xl">
                  Code: <span>{product?.productId}</span>
                </h1>
                <h1 className="mb-8 text-4xl md:text-3xl">
                  Description: <span>{product?.description}</span>
                </h1>
                {getExtraParam(product, "spec_image") && (
                  <button
                    onClick={() => navigate("/specifications/" + product?._id)}
                    className="flex mr-4 bg-gray-300 px-5 py-3 mb-8 rounded-full mt-8"
                  >
                    <h1 className="font-bold text-4xl">Key Specs</h1>
                  </button>
                )}
              </div>
              <div className="w-px h-full bg-gray-300 mx-10"></div>
              <div className="w-1/2 relative">
                <div
                  className="flex"
                  onClick={() => setShowColorOptions(!showColorOptions)}
                >
                  <div className="flex pt-4">
                    <h1 className="text-4xl md:text-3xl font-bold mb-4">
                      Color:{" "}
                      {currentVariant
                        ? colors.filter(
                            (item) => item.hexCode === currentVariant?.hexCode
                          )[0]?.name
                        : colors.filter(
                            (item) =>
                              item.hexCode === product?.variants[0].hexCode
                          )[0]?.name}
                    </h1>
                    <div
                      className={`flex flex-wrap ml-3 mb-4 w-10 h-10 rounded-full`}
                      style={
                        currentVariant
                          ? { background: currentVariant.hexCode }
                          : { background: product?.variants[0].hexCode }
                      }
                    ></div>
                  </div>
                  {product && product!.variants.length > 1 && (
                    <div className="ml-8 mb-4 flex flex-col">
                      {showColorOptions && (
                        <div className="absolute bg-gray-100 transform -translate-y-full -translate-x-3/4 rounded-xl px-6 py-4 mb-10 w-3/5">
                          {product?.variants.map((variant, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => {
                                  setCurrentVariant(variant);
                                  setShowColorOptions(false);
                                }}
                                className="flex justify-between items-center mt-2"
                              >
                                <h1 className="text-3xl mr-8">
                                  {
                                    colors.filter(
                                      (item) => item.hexCode === variant.hexCode
                                    )[0]?.name
                                  }
                                </h1>
                                <div
                                  style={{ backgroundColor: variant.hexCode }}
                                  className="w-10 h-10 flex justify-center items-center rounded-full m-1 cursor-pointer"
                                >
                                  {/* {currentVariant?.name === variant.name && (
                            <img src="/assets/icons/check.svg" alt="" />
                          )} */}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )}
                      <h1 className="text-4xl font-bold mb-4 pt-4">
                        <img
                          src="/assets/icons/down-arrow.svg"
                          alt=""
                          className={`w-8 mt-3 transform ${
                            showColorOptions && "rotate-180"
                          }`}
                        />
                      </h1>
                    </div>
                  )}
                </div>
                <div className="w-full h-px bg-gray-300 mb-8 " />
                <div>
                  <div className="flex">
                    <div>
                      <h1 className="text-4xl md:text-3xl font-bold mb-10">
                        Quantity
                      </h1>
                      <CartItemCount
                        count={itemCount}
                        setCount={setItemCount}
                        isPortrait={isPortrait}
                      />
                    </div>
                    <button
                      onClick={addProductToCart}
                      className="flex mr-4 mt-auto bg-customBtn items-center text-white px-5 py-3 rounded-full"
                    >
                      <img
                        src="/assets/icons/cart-add.svg"
                        className="w-8 h-8"
                        alt="cart"
                      />
                      <h1 className="ml-2 text-4xl md:text-2xl">ADD TO CART</h1>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-px bg-gray-300 my-8 " />
            <p className="text-3xl md:text-xl">
              Disclaimer: *Product colour and image shown in this document are
              for illustration purposes only and may vary from actual product
            </p>
          </div>
        ) : (
          <div style={{ width: "30%" }} className="w-1/4">
            <h1
              className={`font-bold mb-16 ${
                isPortrait ? "text-5xl" : "text-3xl"
              }`}
            >
              {product?.name}
            </h1>
            <h1 className={`mb-8 text-2xl `}>₹{product?.price}</h1>
            <div className="w-full h-px bg-gray-300 mb-8" />
            <h1>
              Collection:{" "}
              <span>{product?.collection.name.split("+")[0] || ""}</span>
            </h1>
            <h1>
              Code: <span>{product?.productId}</span>
            </h1>
            <h1 className="mb-8">
              Description: <span>{product?.description}</span>
            </h1>
            <div className="w-full h-px bg-gray-300" />

            <div className="mt-4">
              <div
                className="flex"
                onClick={() => setShowColorOptions(!showColorOptions)}
              >
                <div className="flex pt-4">
                  <h1 className="text-xl font-bold mb-4">
                    Color:{" "}
                    {currentVariant
                      ? colors.filter(
                          (item) => item.hexCode === currentVariant?.hexCode
                        )[0]?.name
                      : colors.filter(
                          (item) =>
                            item.hexCode === product?.variants[0].hexCode
                        )[0]?.name}
                  </h1>
                  <div
                    className={`flex flex-wrap ml-3 mb-4 w-8 h-8 rounded-full`}
                    style={
                      currentVariant
                        ? { background: currentVariant.hexCode }
                        : { background: product?.variants[0].hexCode }
                    }
                  ></div>
                </div>
                {product && product!.variants.length > 1 && (
                  <div className="ml-3 mb-4 flex flex-col">
                    {showColorOptions && (
                      <div className="absolute bg-gray-100 h-2/4 overflow-auto transform translate-y-20 -translate-x-3/4 rounded-xl px-6 py-4 mb-10 w-1/5 z-50">
                        {product?.variants.map((variant, index) => {
                          return (
                            <div
                              key={index}
                              onClick={() => {
                                setCurrentVariant(variant);
                                setShowColorOptions(false);
                              }}
                              className="flex justify-between items-center mt-2"
                            >
                              <h1 className="text-xl mr-8">
                                {
                                  colors.filter(
                                    (item) => item.hexCode === variant.hexCode
                                  )[0]?.name
                                }
                              </h1>
                              <div
                                style={{ backgroundColor: variant.hexCode }}
                                className="w-8 h-8 flex justify-center items-center rounded-full m-1 cursor-pointer"
                              >
                                {/* {currentVariant?.name === variant.name && (
                            <img src="/assets/icons/check.svg" alt="" />
                          )} */}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                    <h1 className="text-4xl font-bold mb-4 pt-4">
                      <img
                        src="/assets/icons/down-arrow.svg"
                        alt=""
                        className={`w-6 mt-3 transform ${
                          showColorOptions && "rotate-180"
                        }`}
                      />
                    </h1>
                  </div>
                )}
              </div>
              {/* <h1 className="text-xl font-bold mb-4">Color</h1>

              <div className="flex flex-wrap mb-8">
                {product?.variants.map((variant) => (
                  <div
                    key={variant.hexCode}
                    style={{ backgroundColor: variant.hexCode }}
                    className="w-10 h-10 flex justify-center items-center rounded-full m-1 cursor-pointer"
                    onClick={() => {
                      setCurrentVariant(variant);
                    }}
                  >
                    {currentVariant?.name === variant.name && (
                      <img src="/assets/icons/check.svg" alt="" />
                    )}
                  </div>
                ))}
              </div> */}
            </div>
            <div className="w-full h-px bg-gray-300 mb-8 " />
            <div className="flex">
              <div>
                <h1 className="text-xl font-bold mb-3">Quantity</h1>
                <CartItemCount count={itemCount} setCount={setItemCount} />
              </div>
              <button
                onClick={addProductToCart}
                className="flex mr-4 mt-auto bg-customBtn items-center text-white px-5 py-3 rounded-full"
              >
                <img
                  src="/assets/icons/cart-add.svg"
                  className="w-6 h-6"
                  alt=""
                />
                <h1 className="ml-2">ADD TO CART</h1>
              </button>
            </div>
            <div className="w-full h-px bg-gray-300 my-8 " />
            <div className="flex">
              {(currentVariant?.threeDeeModel?.usdz ||
                product?.threeDeeModel?.usdz) &&
                platform !== "android" && (
                  <div className="flex">
                    <button
                      onClick={showInAR}
                      className="flex mr-4 bg-customBtn items-center text-white px-5 py-3 rounded-full"
                    >
                      <h1 className="ml-2">View Live</h1>
                    </button>
                  </div>
                )}
              {getExtraParam(product, "spec_image") && (
                <button
                  onClick={() => navigate("/specifications/" + product?._id)}
                  className="flex bg-gray-300 px-5 py-3 rounded-full"
                >
                  <h1 className="font-bold">Key Specs</h1>
                </button>
              )}
            </div>

            <div className="w-full h-px bg-gray-300 my-8 " />
            <p>
              Disclaimer: *Product colour and image shown in this document are
              for illustration purposes only and may vary from actual product
            </p>
          </div>
        )}
      </div>
    </div>
  );
};
