import axios from "axios";
import { Order } from "../types";

export const getOrders = async (userId?: string) => {
  const whereObj = {
    user: userId,
  };
  const whereClause = encodeURI(JSON.stringify(whereObj));

  const embeddedObj = { "user": 1 };
  const embeddedClause = encodeURI(JSON.stringify(embeddedObj));

  return await axios
    .get<{ _items: Order[] }>(
      process.env.REACT_APP_API_ENDPOINT +
        "content/orders?embedded=" +
        embeddedClause +
        (userId ? "?where=" + whereClause : "")
    )
    .then((res) => res.data._items);
};

//patch order
export const patchOrder = async (_order: Order) => {
  const order = { ..._order };
  delete order.createdAt;
  delete order.updatedAt;

  if (typeof order.user !== "string") {
    order.user = order.user.id as any;
  }

  return await axios
    .patch(
      `https://api.featherlite.helloviewer.io/v1/content/orders/${order._id}`,
      order,
      {
        headers: {
          "Content-Type": "application/json;charset=utf-8",
        },
      }
    )
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error("error creating order:" + err.message);
    });
};

//delete order
export const deleteOrder = async (orderId: string) => {
  return await axios
    .delete(process.env.REACT_APP_API_ENDPOINT + "content/orders/" + orderId, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error("error deleting order:" + err.message);
    });
};

//create order
export const createOrder = async (order: Order) => {
  return await axios
    .post(process.env.REACT_APP_API_ENDPOINT + "content/orders", order, {
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
    })
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      throw new Error("error creating order:" + err.message);
    });
};
