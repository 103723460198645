import { configureStore } from "@reduxjs/toolkit";

import { cartReducer, productFilterReducer, userReducer } from "./globalState";

export const store = configureStore({
  reducer: {
    productFilter: productFilterReducer,
    cart: cartReducer,
    user: userReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
