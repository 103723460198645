/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { PathRouteProps, Route, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux";
import { setUser } from "../store/globalState";
import { getUserFromLocalStorage } from "../utils/localStorage";
interface Props {
  children: JSX.Element;
}

export const PrivateRoute = ({ children }: Props) => {
  const [checkingLoginStatus, setCheckingLoginStatus] = useState(true);

  const session = useAppSelector((state) => state.user);

  let navigate = useNavigate();

  const dispatch = useAppDispatch();

  useEffect(() => {
    (async () => {
      setCheckingLoginStatus(true);

      if (session.user) {
        setCheckingLoginStatus(false);
      } else {
        const user = await getUserFromLocalStorage();
        if (user) {
          dispatch(setUser(user));
          setCheckingLoginStatus(false);
        } else {
          navigate("/");
          setCheckingLoginStatus(false);
        }
      }
    })();
  }, [dispatch, navigate, session.user]);
  if (checkingLoginStatus) {
    return <Fragment>Loading...</Fragment>;
  }

  return children;
};
