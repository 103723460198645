import { Product } from "../types";

const getExtraParam = (
  product: Product | undefined,
  param: "spec_image" | "position" | "background_variant"
) => {
  if (!product?.extraParams) return undefined;
  return product?.extraParams.find((item) => item.key === param)?.value;
};

export default getExtraParam;
