import { useState, useEffect } from "react";

const useIsPortrait = () => {
  const [isPortrait, setIsPortrait] = useState(false);

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    const onOrientationChange = () => {
      const orientation = window.orientation;
      if (width < height || orientation === 0 || orientation === 180) {
        setIsPortrait(true);
      } else {
        setIsPortrait(false);
      }
    };

    if (width < height) {
      setIsPortrait(true);
    } else {
      setIsPortrait(false);
    }

    window.addEventListener("orientationchange", onOrientationChange);
    return () => {
      window.removeEventListener("orientationchange", onOrientationChange);
    };
  }, []);

  return isPortrait;
};

export default useIsPortrait;
