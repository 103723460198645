import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CartItem, User } from "../types";

interface collectionObj {
  name: string;
  id: number | string;
}

const productFilterSlice = createSlice({
  name: "productFilter",
  initialState: {
    exploreBy: "" as "products" | "collections" | "",
    collections: [] as collectionObj[],
    categories: [] as string[],
    variants: [] as string[],
    sortBy: "Price" as "Price" | "New",
    search: "",
  },

  reducers: {
    setExploreBy(
      state,
      action: PayloadAction<"products" | "collections" | "">
    ) {
      state.exploreBy = action.payload;
    },
    setSelectedCollection(state, action: PayloadAction<collectionObj>) {
      state.collections.push(action.payload);
    },
    removeSelectedCollection(state, action: PayloadAction<collectionObj>) {
      const itemExists = state.collections.find(
        (item) => item.id === action.payload.id
      );
      if (itemExists) {
        state.collections = state.collections.filter(
          (item) => item.id !== action.payload.id
        );
      }
    },
    setSelectedCategories(state, action: PayloadAction<string>) {
      const itemExists = state.categories.find(
        (item) => item === action.payload
      );
      if (itemExists) {
        state.categories = state.categories.filter(
          (item) => item !== action.payload
        );
      } else {
        state.categories.push(action.payload);
      }
    },
    setSelectedVariants(state, action: PayloadAction<string>) {
      const itemExists = state.variants.find((item) => item === action.payload);
      if (itemExists) {
        state.variants = state.variants.filter(
          (item) => item !== action.payload
        );
      } else {
        state.variants.push(action.payload);
      }
    },
    clearFilter(state) {
      state.collections = [];
      state.categories = [];
    },
    setSortBy(state, action: PayloadAction<"Price" | "New">) {
      state.sortBy = action.payload;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
  },
});

const cartSlice = createSlice({
  name: "cart",
  initialState: {
    items: [] as CartItem[],
    total: 0,
  },
  reducers: {
    addItemToCart(state, action: PayloadAction<CartItem>) {
      const existingItem = state.items.find(
        (item) => item.id === action.payload.id
      );
      if (existingItem) {
        existingItem.quantity += action.payload.quantity;
        if (existingItem.quantity === 0) {
          state.items = state.items.filter(
            (item) => item.id !== action.payload.id
          );
        }
      } else {
        state.items.push(action.payload);
      }
      state.total =
        state.total + action.payload.product.price * action.payload.quantity;
    },
    removeItemFromCart(state, action: PayloadAction<string>) {
      state.items = state.items.filter((item) => item.id !== action.payload);
      state.total = state.items.reduce(
        (total, item) => total + item.product.price * item.quantity,
        0
      );
    },
    clearCart(state) {
      state.items = [];
      state.total = 0;
    },
  },
});

const userSlice = createSlice({
  name: "user",
  initialState: { user: null as User | null },
  reducers: {
    setUser(state, action: PayloadAction<User>) {
      state.user = action.payload;
    },
    logout(state) {
      state.user = null;
    },
  },
});

export const { setUser, logout } = userSlice.actions;
export const userReducer = userSlice.reducer;

export const {
  setExploreBy,
  setSelectedCategories,
  setSelectedCollection,
  removeSelectedCollection,
  setSelectedVariants,
  clearFilter,
  setSortBy,
  setSearch,
} = productFilterSlice.actions;
export const productFilterReducer = productFilterSlice.reducer;

export const { addItemToCart, removeItemFromCart, clearCart } =
  cartSlice.actions;
export const cartReducer = cartSlice.reducer;
