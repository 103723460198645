import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import Login from "./Pages/Login";
import { PrivateRoute } from "./Components/PrivateRoute";
import { Toaster } from "react-hot-toast";
import ProductDetails from "./Pages/ProductDetails";
import Cart from "./Pages/Cart";
import Orders from "./Pages/Orders";
import List from "./Pages/List";
import useIsPortrait from "./hooks/useIsPortrait";
import Quote from "./Pages/Quote";
import Specifications from "./Pages/Specifications";
import Explore from "./Pages/Explore";
import { Visualize } from "./Pages/Visualize/Visualize";
import { Header } from "./Components/Header";
// import { Collections } from "./Pages/Explore/Collections";
import { Categories } from "./Pages/Explore/Categories";

function App() {
  const isPortrait = useIsPortrait();
  return (
    <BrowserRouter>
      {/* {isPortrait && (
        <div className="App absolute w-screen h-screen z-50 bg-white flex justify-center items-center">
          <h1>Please rotate your device</h1>
        </div>
      )} */}
      <Toaster />
      <Header isPortrait={isPortrait}>
        <Routes>
          <Route path="/" element={<Login isPortrait={isPortrait} />} />
          {/* <Route path="/" element={<Explore />} /> */}
          <Route
            path="/explore"
            element={
              <PrivateRoute>
                <Explore isPortrait={isPortrait} />
              </PrivateRoute>
            }
          />
          {/* <Route
            path="/explore/collections"
            element={
              <PrivateRoute>
                <Collections isPortrait={isPortrait} />
              </PrivateRoute>
            }
          /> */}
          <Route
            path="/explore/categories"
            element={
              <PrivateRoute>
                <Categories />
              </PrivateRoute>
            }
          />

          <Route
            path="/list"
            element={
              <PrivateRoute>
                <List isPortrait={isPortrait} />
              </PrivateRoute>
            }
          />
          <Route
            path="/details/:id"
            element={
              <PrivateRoute>
                <ProductDetails isPortrait={isPortrait} />
              </PrivateRoute>
            }
          />
          <Route
            path="/specifications/:id"
            element={
              <PrivateRoute>
                <Specifications />
              </PrivateRoute>
            }
          />
          <Route
            path="/cart"
            element={
              <PrivateRoute>
                <Cart isPortrait={isPortrait} />
              </PrivateRoute>
            }
          />
          <Route
            path="/orders"
            element={
              <PrivateRoute>
                <Orders />
              </PrivateRoute>
            }
          />
          <Route
            path="/quote/:id"
            element={
              <PrivateRoute>
                <Quote />
              </PrivateRoute>
            }
          />
          <Route
            path="/visualize"
            element={
              <PrivateRoute>
                <Visualize />
              </PrivateRoute>
            }
          />
        </Routes>
      </Header>
    </BrowserRouter>
  );
}

export default App;
