import { useNavigate } from "react-router";
import { useAppDispatch } from "../../hooks/redux";
import BannerCarousel from "./BannerCarousel";
import { clearFilter, setSelectedCollection } from "../../store/globalState";
import { Capacitor } from "@capacitor/core";
interface IProps {
  isPortrait: boolean;
}

export const Explore: React.FC<IProps> = ({ isPortrait }) => {
  const platform = Capacitor.getPlatform();
  const dispatch = useAppDispatch();
  let itemsPortraitIos = [
    {
      image: "/assets/banners/portraitbanner1-1.jpg",
    },
    {
      image: "/assets/banners/portraitbanner2-1.jpg",
    },
    {
      image: "/assets/banners/portraitbanner3-1.jpg",
    },
    {
      image: "/assets/banners/portraitbanner4-1.jpg",
    },
    {
      image: "/assets/banners/portraitbanner5-1.jpg",
    },
  ];

  let itemsPortrait = [
    {
      image: "/assets/banners/portraitbanner2-1.jpg",
    },
    {
      image: "/assets/banners/portraitbanner3-1.jpg",
    },
    {
      image: "/assets/banners/portraitbanner4-1.jpg",
    },
    {
      image: "/assets/banners/portraitbanner5-1.jpg",
    },
  ];

  let itemsLandscape = [
    {
      image: "/assets/banners/landscapebanner1-1.jpg",
    },
    {
      image: "/assets/banners/landscapebanner2-1.jpg",
    },
    {
      image: "/assets/banners/landscapebanner3-1.jpg",
    },
    {
      image: "/assets/banners/landscapebanner4-1.jpg",
    },
    {
      image: "/assets/banners/landscapebanner5-1.jpg",
    },
  ];

  const handleClick = () => {
    dispatch(clearFilter());

    dispatch(
      setSelectedCollection({
        id: 1,
        name: "Swarna",
      })
    );
    dispatch(
      setSelectedCollection({
        id: "621caa943cf5ce2e0f5af035",
        name: "Desire",
      })
    );
    dispatch(
      setSelectedCollection({
        id: "621cad2a28066e651df8b338",
        name: "Regalia",
      })
    );
    dispatch(
      setSelectedCollection({
        id: "621cadf528066e651df8b33a",
        name: "Solitaire",
      })
    );
    dispatch(
      setSelectedCollection({
        id: "621cadc628066e651df8b339",
        name: "Sparkle",
      })
    );

    navigate(`/list`);
  };

  const navigate = useNavigate();
  return (
    <div
      className={` h-screen md:h-full sm:h-full flex ${
        platform === "ios" ? "flex-col" : "flex-col-reverse justify-between"
      } `}
    >
      {isPortrait ? (
        platform === "ios" ? (
          <BannerCarousel items={itemsPortraitIos} />
        ) : (
          <BannerCarousel items={itemsPortrait} />
        )
      ) : (
        <BannerCarousel items={itemsLandscape} />
      )}
      <div
        className={`w-full flex flex-col justify-center ${
          isPortrait ? "px-10" : "items-center flex-grow"
        }`}
      >
        <div className="flex justify-center mt-3 flex-col mb-3 px-4 pb-4 bg-no-repeat bg-cover w-full">
          <div className="flex flex-col  ml-auto mr-auto">
            <h1
              className={`text-center mb-3 ${
                isPortrait ? "text-7xl mt-8 md:mt-24 mb-8" : " text-5xl mt-3"
              }`}
            >
              Taking you from ordinary to extraordinary.
            </h1>
            <h1
              className={`text-center font-bold ${
                isPortrait ? "text-5xl md:text-4xl" : "text-2xl"
              }`}
            >
              Explore our premium collection of Bathroom and Kitchen Fittings
            </h1>
          </div>
        </div>
        <div className={`flex w-full mt-3 ${isPortrait && "flex-col w-3/5"}`}>
          <div
            className={`flex w-full justify-center flex-row ${
              isPortrait ? "px-14 flex-col md:flex-row" : " px-20"
            }`}
          >
            <div
              className={`flex ${
                isPortrait ? "w-full justify-center" : "w-2/3"
              }`}
            >
              <img
                className={`mb-4 ${isPortrait && "w-1/2 md:w-full"}`}
                src="/assets/banners/artistry.png"
                alt=""
                style={
                  isPortrait
                    ? {
                        marginTop: "4rem",
                        objectFit: "cover",
                        objectPosition: "center top",
                      }
                    : { width: "450px", height: "450px", objectFit: "contain" }
                }
              />
            </div>
            <div
              className={`flex flex-col justify-center  ${
                isPortrait ? "ml-0 items-center md:items-start md:ml-5" : "ml-8"
              }`}
            >
              <h1
                className={` mb-4 ${
                  isPortrait
                    ? "mt-12 md:mt-5 text-8xl md:text-6xl md:text-left"
                    : "text-5xl"
                }`}
                style={{ color: "#8A3A22" }}
              >
                Artistry Collection
              </h1>

              <p
                className={`mb-4 ${
                  isPortrait
                    ? "text-5xl px-16 mt-8 text-center leading-normal md:text-left md:mt-0 md:px-0 md:text-3xl md:leading-9"
                    : "text-3xl leading-9"
                }`}
              >
                Whether home, office or a commercial space, our thoughtful range
                of Artistry fittings is designed to create memorable experiences
                for all life spaces.
              </p>

              <button
                onClick={() => handleClick()}
                className={`w-max border-2 border-black rounded-full px-8 py-8 md:px-4 md:py-4 ${
                  isPortrait ? "text-5xl mt-8 md:text-3xl md:mt-3" : "text-xl"
                }`}
              >
                Artistry Collection
              </button>
            </div>
          </div>
        </div>
      </div>
      {platform !== "ios" && (
        <div
          className={` hidden md:flex ${
            platform === "ios" ? "mt-24 px-14" : ""
          }`}
        >
          <img
            src="/assets/banners/portraitbanner1.jpg"
            alt="artistry"
            // style={{ height: "470px" }}
            className="w-full"
          />
        </div>
      )}
    </div>
  );
};
