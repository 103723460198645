import React from "react";
import ReactDOM from "react-dom";

interface Props {
  children: React.ReactNode;
}

export const Modal = ({ children }: Props) => {
  return ReactDOM.createPortal(
    <div
      style={{ backgroundColor: "rgb(0,0,0,0.5)" }}
      className="w-screen h-screen absolute top-0 left-0 flex justify-center items-center"
    >
      {children}
    </div>,
    document.body
  );
};
