import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router";
import { useCategories, useProducts } from "../../api";
// import { useCollections } from "../../api";
import { Select } from "../../Components/Select";
// import { categories, collections } from "../../const";
import collectionData from "./collectionData.json";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import {
  setSearch,
  setSelectedCategories,
  setSelectedCollection,
  removeSelectedCollection,
  setSelectedVariants,
  setSortBy,
} from "../../store/globalState";
import { Product } from "../../types";

interface IProps {
  isPortrait: boolean;
}

const SubHeader = ({
  productCount,
  isPortrait,
  setShowFilters,
  showFilters,
}: {
  productCount: number;
  isPortrait: boolean;
  setShowFilters: (showFilters: boolean) => void;
  showFilters: boolean;
}) => {
  const dispatch = useAppDispatch();
  const sortBy = useAppSelector((state) => state.productFilter.sortBy);
  const search = useAppSelector((state) => state.productFilter.search);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setSearch(e.target.value));
  };

  return (
    <div>
      <div className="flex mt-10 md:mt-4 mb-4">
        {showFilters && (
          <div
            onClick={() => setShowFilters(true)}
            className="md:hidden text-5xl font-bold ml-2 border px-8 flex items-center rounded-full"
          >
            <h1>Filters</h1>
            <img
              src="/assets/icons/down-arrow.svg"
              alt=""
              className="ml-3 w-9 transform -rotate-90"
            />
          </div>
        )}
        <div className="border bg-white rounded-full px-8 py-2 flex items-center ml-auto">
          <input
            style={{
              color: "black",
            }}
            className={isPortrait ? "text-5xl md:text-2xl" : "text-xl"}
            type="text"
            placeholder="search..."
            value={search}
            onChange={handleSearch}
          />
          <img
            src={search ? "/assets/icons/x.svg" : "/assets/icons/search.svg"}
            className={`${search ? "cursor-pointer" : ""} w-9 md:w-8 lg:w-6`}
            alt=""
            onClick={() => dispatch(setSearch(""))}
          />
        </div>
        <Select
          className={`ml-4 w-max ${
            isPortrait ? "text-5xl md:text-2xl" : " text-base"
          }`}
          options={["Price", "New"]}
          onChange={(value) => dispatch(setSortBy(value as any))}
          value={sortBy}
        />
      </div>
    </div>
  );
};

const Products: React.FC<IProps> = ({ isPortrait }) => {
  const { data: products, isLoading } = useProducts();

  const navigate = useNavigate();

  const categoryFilter = useAppSelector(
    (state) => state.productFilter.categories
  );

  const collectionsFilter = useAppSelector(
    (state) => state.productFilter.collections
  );

  const variantsFilter = useAppSelector(
    (state) => state.productFilter.variants
  );

  const sortBy = useAppSelector((state) => state.productFilter.sortBy);
  const search = useAppSelector((state) => state.productFilter.search);

  const filterHiddenProducts = useMemo(() => {
    return products?.filter((product) => product.isVisibleOnCompanion);
  }, [products]);

  const searchedProducts = useMemo(() => {
    if (search && filterHiddenProducts) {
      return filterHiddenProducts.filter((product) =>
        (product.name?.toLowerCase() || "").includes(search.toLowerCase())
      );
    } else if (filterHiddenProducts) {
      return filterHiddenProducts;
    }
  }, [search, filterHiddenProducts]);

  const filteredProducts = useMemo(() => {
    let productsToFilter = [...(searchedProducts || [])];

    if (categoryFilter.length > 0) {
      productsToFilter = productsToFilter.filter((product) =>
        categoryFilter.includes(product.category.name || "")
      );
    }

    if (collectionsFilter.length > 0) {
      productsToFilter = productsToFilter.filter((product) =>
        collectionsFilter.find((collection) => {
          return collection.id === product.collection._id;
        })
      );
    }

    if (variantsFilter.length > 0) {
      productsToFilter = productsToFilter.filter((product) => {
        let variantPresent = false;
        product.variants.some((variant) => {
          if (
            variantsFilter.includes(variant.hexCode) ||
            variantsFilter.includes(variant.name)
          ) {
            variantPresent = true;
            return true;
          }
          return false;
        });
        return variantPresent;
      });
    }
    return productsToFilter;
  }, [categoryFilter, collectionsFilter, searchedProducts, variantsFilter]);

  const sortedProducts = useMemo(() => {
    if (sortBy === "Price") {
      return filteredProducts.sort((a, b) => (a.price || 0) - (b.price || 0));
    } else if (sortBy === "New") {
      return filteredProducts.sort((a, b) => (b.price || 0) - (a.price || 0));
    } else {
      return filteredProducts;
    }
  }, [filteredProducts, sortBy]);

  if (isLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <h1 className="text-center text-2xl">Loading Products...</h1>
      </div>
    );
  }
  if (sortedProducts.length === 0) {
    return (
      <div className="w-full h-1/2 flex justify-center items-center">
        <h1 className="text-center text-2xl">
          {products?.length === 0
            ? "No Products Found"
            : "No Products Found for selected filter(s)"}
        </h1>
      </div>
    );
  }
  return (
    <div
      className={`grid md:justify-items-center gap-14 md:gap-16 p-4 ${
        isPortrait
          ? "grid-cols-2 px-20 md:px-0  md:grid-cols-3"
          : "grid-cols-3 "
      }`}
    >
      {sortedProducts.map((product) => (
        <div
          key={product._id}
          onClick={() => navigate("/details/" + product._id)}
          className="cursor-pointer flex flex-col"
        >
          <img
            src={product.thumbnailUrl || "/assets/placeholder.jpg"}
            style={
              isPortrait
                ? { width: "25rem", height: "25rem" }
                : { width: "18rem", height: "15rem" }
            }
            className=" mb-4 object-contain flex "
            alt=""
          />
          <h1 className={`font-bold mb-2 text-5xl md:text-2xl `}>
            {product.name}
          </h1>
          <h1 className={isPortrait ? "text-5xl md:text-2xl" : "text-xl"}>
            {product?.price === 0
              ? "Price on request"
              : product && `₹${product?.price}`}
          </h1>
        </div>
      ))}
    </div>
  );
};

interface CollectionType {
  name: string;
  id: string | number;
  children?: CollectionType[];
}

const CheckBox = ({
  checked,
  onToggle,
  text,
  count,
  collection,
  id,
}: {
  checked: boolean;
  onToggle: (value: boolean) => void;
  text: string;
  count: number;
  collection?: CollectionType[];
  id?: number | string;
}) => {
  const dispatch = useAppDispatch();
  const selectedCollections = useAppSelector(
    (state) => state.productFilter.collections
  );

  const [showChildren, setShowChildren] = useState(false);

  const removeCollection = (
    childCollections: CollectionType[],
    parentId?: number | string
  ) => {
    if (parentId !== undefined) {
      dispatch(
        removeSelectedCollection({
          name: text,
          id: parentId,
        })
      );
    }
    for (let i = 0; i < childCollections.length; i++) {
      dispatch(
        removeSelectedCollection({
          name: childCollections[i].name,
          id: childCollections[i].id,
        })
      );
      if (
        childCollections[i].children &&
        childCollections[i].children!.length > 0
      ) {
        removeCollection(childCollections[i].children!, parentId);
      }
    }
  };
  const addCollection = (childCollections: CollectionType[]) => {
    for (let i = 0; i < childCollections.length; i++) {
      dispatch(
        setSelectedCollection({
          name: childCollections[i].name,
          id: childCollections[i].id,
        })
      );
      if (
        childCollections[i].children &&
        childCollections[i].children!.length > 0
      ) {
        addCollection(childCollections[i].children!);
      }
    }
  };
  const check = (childCollections: CollectionType[]) => {
    for (let i = 0; i < childCollections.length; i++) {
      if (
        !selectedCollections.find((item) => item.id === childCollections[i].id)
      ) {
        return false;
      }
    }
    return true;
  };

  const handleCollectionFilter = () => {
    onToggle(!checked);
    if (collection) {
      setShowChildren(true);
      if (check(collection)) {
        dispatch(removeSelectedCollection({ name: text, id: id! }));

        removeCollection(collection, id);
      } else {
        dispatch(setSelectedCollection({ name: text, id: id! }));

        addCollection(collection);
      }
    } else {
      if (selectedCollections.find((item) => item.id === id)) {
        dispatch(removeSelectedCollection({ name: text, id: id! }));
      }
    }
  };
  return (
    <div>
      <div className="flex text-2xl items-center justify-between my-1 cursor-pointer">
        <div
          onClick={() => handleCollectionFilter()}
          className="flex text-2xl items-center my-2 cursor-pointer"
        >
          <img
            src={`/assets/icons/${
              checked ? "checked-square" : "empty-square"
            }.svg`}
            className="mr-2 h-4 w-4"
            alt=""
          />
          <span className="flex w-full">{text}</span>
          {/* <span className="flex ml-3">({count})</span> */}
        </div>
        {collection && (
          <img
            onClick={() => {
              setShowChildren(!showChildren);
            }}
            src="/assets/icons/down-arrow.svg"
            alt=""
            className="mr-5 h-4 lg:h-auto"
            style={
              showChildren
                ? { transform: "none" }
                : { transformOrigin: "center", transform: "rotate(-90deg)" }
            }
          />
        )}
      </div>
      {collection && (
        <div className={showChildren ? "flex flex-col" : "hidden"}>
          {collection?.map(({ id, name, children }) => {
            const handleToggle = () => {
              if (selectedCollections.find((item) => item.id === id)) {
                // dispatch(removeCollection({ name, id }));
              } else {
                dispatch(setSelectedCollection({ name, id }));
              }
            };
            return (
              <div key={id} className="pl-5">
                <CheckBox
                  text={name}
                  count={0}
                  checked={
                    !!selectedCollections?.find((item) => item.id === id)
                  }
                  onToggle={() => handleToggle()}
                  collection={children}
                  id={id}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

// const colors = [
//   { name: "Black", hexCode: "#000000" },
//   { name: "Matte Blue", hexCode: "#0d5c82" },
//   { name: "Antique Gold", hexCode: "#b28c5f" },
//   { name: "Light Gold", hexCode: "#ab9864" },
//   { name: "Copper", hexCode: "#b28c5f" }, //hex code same as antique gold
//   { name: "Matte Black", hexCode: "#141313" },
//   { name: "Gun Metal", hexCode: "#6c6c6c" },
//   { name: "Matte Chocolate", hexCode: "#59483a" },
//   { name: "Matte Steel", hexCode: "#e7e6e5" },
//   { name: "Matte Gold", hexCode: "#b9a46a" },
//   { name: "Icy White", hexCode: "#98a2ab" },
//   { name: "Gun Metal", hexCode: "#514f4f" },
//   { name: "Rose Gold", hexCode: "#d6a58e" },
//   { name: "Chocolate", hexCode: "#483a2a" },
//   { name: "Gold", hexCode: "#a99254" },
//   { name: "Glossy Blue", hexCode: "#0d5c82" }, //hex code same as matte blue
// ];

const colors = [
  { name: "Rose Gold", hexCode: "#d6a58e" },
  { name: "Gold", hexCode: "#a99254" },
  { name: "Black Gold", hexCode: "#514f4f" },
  { name: "Onyx Gold", hexCode: "#b28c5f" },
  { name: "Chrome", hexCode: "#C0C0C0" },
];

interface SidebarProps {
  products: Product[] | undefined;
  showFilters: boolean;
  setShowFilters: (showFilters: boolean) => void;
}

const Sidebar = ({ products, showFilters, setShowFilters }: SidebarProps) => {
  const [productCount, setProductCount] = useState<{
    [key: string]: number;
  }>({});
  const selectedCategories = useAppSelector(
    (state) => state.productFilter.categories
  );
  const selectedCollections = useAppSelector(
    (state) => state.productFilter.collections
  );
  const selectedVariants = useAppSelector(
    (state) => state.productFilter.variants
  );

  // const { data: collections } = useCollections();
  const { data: categories } = useCategories();

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!products) {
      return;
    }
    const count = {
      ...products.reduce((acc, product) => {
        if (!product?.collection) return acc;
        if (acc[product?.collection.name]) {
          acc[product.collection.name] += 1;
        } else {
          acc[product.collection.name] = 1;
        }
        return acc;
      }, {} as any),
      ...products
        .filter((product) =>
          selectedCollections.length === 0
            ? true
            : selectedCollections.find(
                (collection) => collection.id === product.collection._id
              )
        )
        .reduce((acc, product) => {
          if (!product?.category) return acc;
          if (acc[product?.category.name]) {
            acc[product.category.name] += 1;
          } else {
            acc[product.category.name] = 1;
          }
          return acc;
        }, {} as any),
    };
    setProductCount(count);
  }, [products, selectedCollections]);

  const handleColorFilter = (hexCode: string, name: string) => {
    dispatch(setSelectedVariants(hexCode));
    dispatch(setSelectedVariants(name));
  };

  return (
    <div className="pr-4 border-r h-full overflow-scroll pb-8 flex flex-col">
      <div className="ml-16">
        <div
          className="flex items-center"
          onClick={() => setShowFilters(false)}
        >
          <h1 className="font-bold text-6xl md:text-2xl mb-8 mt-14 md:mt-16">
            Filters
          </h1>
          <div className="font-bold text-4xl md:text-2xl mb-8 mt-14 md:hidden">
            <img
              src="/assets/icons/down-arrow.svg"
              alt=""
              className="ml-3 w-9 transform rotate-90"
            />
          </div>
        </div>
        <h1 className="font-bold mb-8 text-6xl md:text-2xl">
          Explore by Collection
        </h1>

        {collectionData?.map(({ id, name, children }) => (
          <div key={id} className="my-4">
            <CheckBox
              text={name}
              count={productCount[name] || children.length}
              checked={
                !!selectedCollections.find((collection) => id === collection.id)
              }
              onToggle={() => dispatch(setSelectedCollection({ name, id }))}
              collection={children}
              id={id}
            />
          </div>
        ))}

        <div className="flex mt-16 mb-8">
          <h1 className="font-bold text-4xl md:text-2xl">Explore by Area</h1>
        </div>
        {categories?.map((category, index) => (
          <div key={index} className="my-2">
            <CheckBox
              text={category.name}
              count={productCount[category.name]}
              checked={selectedCategories.includes(category.name)}
              onToggle={() => dispatch(setSelectedCategories(category.name))}
            />
          </div>
        ))}

        <div className="mt-16">
          <h1 className="font-bold text-4xl md:text-2xl">Explore by Color</h1>
          {/* <div className="w-full h-px bg-gray-200 mb-2" /> */}
          <div className="flex flex-col mt-5">
            {colors.map((color, index) => (
              <div
                className="flex items-center mt-5 justify-between mr-3"
                onClick={() => handleColorFilter(color.hexCode, color.name)}
                key={index}
              >
                <div className="flex items-center">
                  <img
                    src={`/assets/icons/${
                      selectedVariants.includes(color.name)
                        ? "checked-square"
                        : "empty-square"
                    }.svg`}
                    className="mr-2 h-4 w-4"
                    alt=""
                  />
                  <p className="text-4xl md:text-2xl ">{color.name}</p>
                </div>
                <div
                  style={{ backgroundColor: color.hexCode }}
                  className=" w-14 h-14 md:w-8 md:h-8 ml-3 rounded-full flex items-center justify-center"
                ></div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export const List: React.FC<IProps> = ({ isPortrait }) => {
  const { data: products } = useProducts();
  const filterHiddenProducts = useMemo(() => {
    return products?.filter((product) => product.isVisibleOnCompanion);
  }, [products]);
  const [showFilters, setShowFilters] = useState<boolean>(false);

  return (
    <div className="flex h-full relative">
      <div className="w-2/5 md:w-1/4 hidden md:flex">
        <Sidebar
          products={filterHiddenProducts}
          showFilters
          setShowFilters={setShowFilters}
        />
      </div>
      <div
        className={`w-3/5 md:w-1/4 absolute md:hidden overflow-y-auto z-10 transform transition-transform duration-300 ${
          showFilters ? "-translate-x-0 " : "-translate-x-full"
        }`}
        style={{ background: "#F4F4FC" }}
      >
        <Sidebar
          products={filterHiddenProducts}
          showFilters
          setShowFilters={setShowFilters}
        />
      </div>
      <div className="w-full md:w-3/4 px-4 overflow-y-auto">
        <SubHeader
          productCount={filterHiddenProducts?.length || 0}
          isPortrait={isPortrait}
          showFilters
          setShowFilters={setShowFilters}
        />
        <Products isPortrait={isPortrait} />
      </div>
    </div>
  );
};
